import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {// 30 seconds

  private appUpdateSubject = new BehaviorSubject<boolean>(false);
  appUpdate$ = this.appUpdateSubject.asObservable();

  constructor(appRef: ApplicationRef, private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const every30Seconds$ = interval(30000);
      const every30SecondsOnceAppIsStable$ = concat(appIsStable$, every30Seconds$);

      every30SecondsOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());

      swUpdate.available.subscribe(event => {
        swUpdate.activateUpdate().then(() => this.appUpdateSubject.next(true));
      });

      swUpdate.unrecoverable.subscribe(event => {
        if (confirm(`An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.')) {
          document.location.reload();
        }
      });
    }
  }
}
