import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { ClockingService } from '../../services/clocking.service';
import { finalize, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clocking-dialog',
  templateUrl: './clocking-dialog.component.html',
  styleUrls: ['./clocking-dialog.component.scss']
})
export class ClockingDialogComponent implements OnInit {
  loading = false;

  constructor(private dialogRef: MatDialogRef<ClockingDialogComponent>,
              private httpClient: HttpClientService,
              private authService: AuthService,
              private clockingService: ClockingService,
              private router: Router) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
  }

  clockin(): void {
    this.loading = true;

    this.httpClient.get('/api/tech/clockin').pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(
      response => {
        const authToken = this.authService.jwtToken;
        authToken.user.dayIsStarted = true;
        this.clockingService.isDayStartedEvent.emit(true);
        this.authService.jwtToken = authToken;
        this.dialogRef.close();
      }
    );
  }


}
