import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestTotalService implements OnDestroy {

  private totalRequestSubject = new BehaviorSubject<number>(0);
  public totalRequest$ = this.totalRequestSubject.asObservable();

  set total(numberRequest: number) {
    this.totalRequestSubject.next(numberRequest);
  }

  ngOnDestroy(): void {
    this.totalRequestSubject.complete();
  }
}
