<button #originRef
        (click)="showPopover()"
        [class.active]="dropdownOpen"
        class="button"
        [ngClass]="{'bg-primary-light': qtyNotifications > 0}"
        mat-icon-button
        [matBadge]="qtyNotifications"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadgeHidden]="qtyNotifications === 0"
        type="button">
  <mat-icon [icIcon]="icNotificationsActive"></mat-icon>
</button>
