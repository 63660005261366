import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  private apiPrefix = `${environment.apiBaseUrl}`;
  private mimeType = 'application/ld+json';
  private httpOptions: any = {
    headers: null,
    params: null,
    observe: 'body',
  };

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  setMimeJson() {
    this.mimeType = 'application/json';
  }

  setMimeJsonLd() {
    this.mimeType = 'application/ld+json';
  }

  get(path: string, params?: HttpParams | {}): Observable<any> {
    this.httpOptions.headers = this.headers(false, this.authService.jwtToken?.token);
    this.httpOptions.params = params;

    return this.httpClient.get(`${this.apiPrefix}${normalizePath(path)}`, this.httpOptions);
  }

  post( path: string, body: any = {}, params?: HttpParams | {} ): Observable<any> {
    this.httpOptions.headers = this.headers(true, this.authService.jwtToken?.token);
    this.httpOptions.params = params;

    return this.httpClient.post(`${this.apiPrefix}${normalizePath(path)}`, JSON.stringify(body), this.httpOptions);
  }

  put( path: string, body: any = {}, params?: HttpParams | {} ): Observable<any> {
    this.httpOptions.headers = this.headers(true, this.authService.jwtToken?.token);
    this.httpOptions.params = params;

    return this.httpClient.put(`${this.apiPrefix}${normalizePath(path)}`, JSON.stringify(body), this.httpOptions);
  }

  delete( path: string, params?: HttpParams | {} ): Observable<any> {
    this.httpOptions.headers = null;

    if (this.authService.jwtToken?.token) {
      this.httpOptions.headers = new HttpHeaders({
        Authorization: `Bearer ${this.authService.jwtToken.token}`
      });
    }

    this.httpOptions.params = params;

    return this.httpClient.delete(`${this.apiPrefix}${normalizePath(path)}`, this.httpOptions);
  }

  upload(path: string, formData: FormData, params?: HttpParams | {}): Observable<HttpEvent<any>> {
    const request = new HttpRequest('POST', `${this.apiPrefix}${normalizePath(path)}`, formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        Accept: this.mimeType,
        Authorization: this.authService.jwtToken !== null ? `Bearer ${this.authService.jwtToken.token}` : undefined
      })
    });

    return this.httpClient.request(request);

    // this.httpOptions.headers = this.headers(false, this._auth.jwtToken !== null);
    // this.httpOptions.params = params;
    // this.httpOptions.reportProgress = true;

    // return this._httpClient.post(`${this.apiPrefix}${normalizePath(path)}`, formData, this.httpOptions);
  }

  private headers(haveContentType: boolean, authorizationToken: string): HttpHeaders {
    let headers = new HttpHeaders({ Accept: this.mimeType});

    if (haveContentType) {
      headers = headers.append('Content-Type',  this.mimeType);
    }

    if (authorizationToken) {
      headers = headers.append('Authorization',  `Bearer ${authorizationToken}`);
    }

    return headers;
  }

}

function normalizePath(path: string): string {
  const array = path.split('/');
  let _path = '';
  let i = 0;

  array.forEach(value => {
    if (value !== '') {
      if (i === 0) {
        _path += `${value}`;
      } else {
        _path += `/${value}`;
      }
      i++;
    }
  });

  return _path;
}
