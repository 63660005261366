import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClockingService {
  isDayStartedEvent = new EventEmitter<boolean>();

  get isDayStarted(): boolean {
    return this.authService.authUser?.dayIsStarted;
  }

  constructor(private authService: AuthService) {
  }
}
