<h2 mat-dialog-title>Clockin Day</h2>

<div mat-dialog-content class="mat-typography">
  <p>
    Click clockin to start.
  </p>
</div>

<div mat-dialog-actions style="width: 100%; display: flex; justify-content: end">
  <button (click)="logout()" type="button" mat-raised-button color="accent">
    <span>Logout</span>
  </button>

  <button (click)="clockin()" [disabled]="loading" type="button" mat-raised-button color="primary">
    <span *ngIf="!loading">Clockin</span>
    <span *ngIf="loading">Loading...</span>
  </button>
</div>

