<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [icIcon]="icPerson"></mat-icon>
      </div>
      <div class="dropdown-heading">{{username}}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a (click)="openChangePasswordDialog()"
       href="javascript:void(0);"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="icLock" class="notification-icon text-teal" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label" i18n>Change Password</div>
        <div class="notification-description" i18n>Change your password at any time</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
    <a (click)="clockout()" *ngIf="authTech"
       href="javascript:void(0);"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="icTimeOff" class="notification-icon text-red" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label" i18n>Clockout</div>
        <div class="notification-description" i18n>Confirm ClockOut.</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
    <!-- <a (click)="close()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="item.icon"
                [ngClass]="item.colorClass"
                class="notification-icon"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a> -->
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="end center">
    <a (click)="logout()" color="primary" mat-button i18n>LOGOUT</a>
  </div>
</div>
