import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { PopoverService } from 'src/app/shared/components/popover/popover.service';
import { SwUpdateService } from 'src/app/shared/services/sw-update.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';

@Component({
  selector: 'app-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;
  qtyNotifications = 0;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private swUpdateService: SwUpdateService
  ) {
    this.swUpdateService.appUpdate$.subscribe(update => {
      if (update) {
        this.qtyNotifications++;
        // this.cd.markForCheck();
      }
    });
  }

  ngOnInit(): void {
  }

  showPopover() {
    this.dropdownOpen = true;
    // this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      // this.cd.markForCheck();
    });
  }

}
