import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockoutDialogComponent } from './clockout-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [ClockoutDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ClockoutDialogComponent]
})
export class ClockoutDialogModule { }
