import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private langSubject: BehaviorSubject<'en'|'es'>;
  lang$: Observable<'en'|'es'>;

  get lang(): 'en' | 'es' {
    try {
      const lang = localStorage.getItem('lang');
      if (lang === 'en' || lang === 'es') {
        return lang;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  set lang(lang: 'en'|'es') {
    localStorage.setItem('lang', lang);
    this.langSubject.next(lang);
  }

  constructor(@Inject(LOCALE_ID) locale: 'en'|'es') {
    const lang = this.lang;
    if (lang) {
      this.langSubject = new BehaviorSubject<'en'|'es'>(lang);
    } else {
      this.langSubject = new BehaviorSubject<'en'|'es'>(locale);
    }

    this.lang$ = this.langSubject.asObservable();

    /* if (lang) {
      this.langSubject = new BehaviorSubject<'en'|'es'>(lang);
    } else {
      let defaultLang;
      if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        defaultLang = locale;
      }

      const wn = window.navigator as any;
      lang = wn.languages ? wn.languages[0] : defaultLang;
      lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;

      if (lang.length > 2) {
        if (lang.split('-')[0] === 'es') {
          lang = 'es';
        } else {
          lang = 'en';
        }
      }
    }

    this.langSubject = new BehaviorSubject<'en'|'es'>(lang);
    this.lang$ = this.langSubject.asObservable(); */
  }
}
