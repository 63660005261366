import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { StoreGuard } from './shared/guards/store.guard';
import { UserGuard } from './shared/guards/user.guard';
import { LayoutComponent } from './shared/layout/layout.component';
import { NotIsGrandsonGuard } from './shared/guards/not-is-grandson.guard';
import { TechGuard } from './shared/guards/tech.guard';
import {AdminGuard} from './shared/guards/admin.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canLoad: [NoAuthGuard]
  },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AdminGuard]
      },
      {
        path: 'stores',
        loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
        canLoad: [AuthGuard, UserGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        canLoad: [AuthGuard, UserGuard, NotIsGrandsonGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        canLoad: [AuthGuard, StoreGuard]
      },
      {
        path: 'repairs',
        loadChildren: () => import('./pages/repairs/repairs.module').then(m => m.RepairsModule),
        canLoad: [AuthGuard, UserGuard, TechGuard]
      },
      //{ path: '',   redirectTo: '/dashboard', pathMatch: 'full' }
    ]
  },
  //{ path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
