import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../services/language.service';

@Injectable()
export class LocaleQueryInterceptor implements HttpInterceptor {

  lang: 'en' | 'es';

  constructor(private languageService: LanguageService) {
    this.languageService.lang$.subscribe(lang => this.lang = lang);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /*let params = request.params;
    params = params.append('locale', this.lang);*/
    request = request.clone({
      setParams: {locale: 'es'}
    });
    return next.handle(request);
  }
}
