import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpClientService } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { ClockingService } from '../../services/clocking.service';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-clockout-dialog',
  templateUrl: './clockout-dialog.component.html',
  styleUrls: ['./clockout-dialog.component.scss']
})
export class ClockoutDialogComponent implements OnInit {
  loading = false;

  constructor(private dialogRef: MatDialogRef<ClockoutDialogComponent>,
              private httpClient: HttpClientService,
              private authService: AuthService,
              private clockingService: ClockingService) { }

  ngOnInit(): void {
  }

  clockout(): void {
    this.loading = true;

    this.httpClient.get('/api/tech/clockout').pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(
      response => {
        const authToken = this.authService.jwtToken;
        authToken.user.dayIsStarted = false;
        this.clockingService.isDayStartedEvent.emit(false);
        this.authService.jwtToken = authToken;
        this.dialogRef.close();
      }
    );
  }

}
