<template #passwordVisibilityTooltip i18n>Toggle Visibility</template>
<template #badRequestI18n i18n>The data contains errors.</template>

<form (ngSubmit)="submit()" [formGroup]="form">
  <div fxLayout="column" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
      <h2  class="headline m-0" fxFlex="auto" i18n>
        Change Password
      </h2>

      <button [disabled]="loading" class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>

    <app-alert [isShow]="alertErrorIsShow" [color]="'warn'" (dimissEvent)="alertErrorIsShow = false" [styles]="'margin-top: 7px;'">
      <div content>{{asyncValidatorMsgDesconocido}}</div>
    </app-alert>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">

    <div class="flex flex-col sm:flex-row" style="align-items: center;">

      <mat-form-field class="mt-6 flex-auto" style="align-items: center;">
        <mat-label i18n>Password</mat-label>
        <input [type]="inputPasswordType"
               required
               formControlName="password"
               maxlength="100"
               autocomplete="new-password"
               matInput>

        <mat-icon *ngIf="visiblePassword" [icIcon]="icLockOpen" class="mr-3" matPrefix></mat-icon>
        <mat-icon *ngIf="!visiblePassword" [icIcon]="icLock" class="mr-3" matPrefix></mat-icon>

        <mat-error *ngIf="form.get('password').hasError('required')">
          <mat-icon>error</mat-icon> <span i18n>This value is required.</span>
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('apiError')">
          <mat-icon>error</mat-icon> <span>{{asyncValidatorMsg.plainPassword}}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label i18n>Repeat password</mat-label>
        <input [type]="inputPasswordType"
               [errorStateMatcher]="matcher"
               formControlName="confirmPassword"
               maxlength="100"
               autocomplete="off"
               matInput>

        <mat-icon *ngIf="visiblePassword" [icIcon]="icLockOpen" class="mr-3" matPrefix></mat-icon>
        <mat-icon *ngIf="!visiblePassword" [icIcon]="icLock" class="mr-3" matPrefix></mat-icon>

        <mat-error *ngIf="form.hasError('notSame')">
          <mat-icon>error</mat-icon> <span>This value does not match the password.</span>
        </mat-error>
      </mat-form-field>

      <button (click)="passwordVisibility()"
              class="text-secondary"
              mat-icon-button
              type="button"
              style="margin-left: 20px;"
              matTooltip="{{passwordVisibilityTooltip.innerText}}"
              color="primary">
        <mat-icon *ngIf="visiblePassword" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visiblePassword" [icIcon]="icVisibilityOff"></mat-icon>
      </button>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button" [disabled]="loading" i18n>CANCEL</button>
    <button color="primary" mat-button type="submit" [disabled]="loading">
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <mat-progress-spinner *ngIf="loading" strokeWidth="2" [diameter]="20" mode="indeterminate"></mat-progress-spinner>
        <span [class.d-none]="loading" style="margin-left: 8px;">
          <span i18n>SAVE</span>
        </span>
        <span [class.d-none]="!loading" style="margin-left: 8px;" i18n>LOADING...</span>
      </div>
    </button>
  </mat-dialog-actions>
</form>
