import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, take } from 'rxjs/operators';
import { MatErrorStateMatcher } from 'src/app/shared/form/mat-error-state-matcher';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BAD_REQUEST, HttpClientService } from 'src/app/shared/services/http-client.service';
import { checkPasswordsValidator } from 'src/app/shared/validation/check-passwords.validator';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icLock from '@iconify/icons-ic/twotone-lock';
import icPassword from '@iconify/icons-ic/twotone-security';
import icLockOpen from '@iconify/icons-ic/twotone-lock-open';
import icClose from '@iconify/icons-ic/twotone-close';
import { ApiBadRequest, Violation } from 'src/app/shared/api-schemas/api-bad-request.schema';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  form: FormGroup;
  loading = false;
  '@id': string;
  inputPasswordType = 'password';
  visiblePassword = false;
  matcher = new MatErrorStateMatcher();
  alertErrorIsShow = false;

  icPassword = icPassword;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  icLock = icLock;
  icLockOpen = icLockOpen;
  icClose = icClose;

  asyncValidatorMsg = {
    plainPassword: '',
  };

  asyncValidatorMsgDesconocido: string;

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              private httpClientService: HttpClientService,
              private authService: AuthService) {

    this['@id'] = this.authService.authUser['@id'];
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      confirmPassword: new FormControl(''),
    }, [checkPasswordsValidator]);
  }

  submit(): void {
    if (this.form.valid) {
      this.loading = true;
      this.dialogRef.disableClose = true;
      this.httpClientService.put(this['@id'], {plainPassword: this.form.get('password').value}).pipe(
        take(1),
        finalize(() => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        })
      ).subscribe(
        () => this.dialogRef.close(),
        (httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.status === BAD_REQUEST) {
            const error: ApiBadRequest = httpErrorResponse.error;
            if (httpErrorResponse.error['@type'] === 'ConstraintViolationList') {
              error.violations.forEach((violation: Violation) => {
                if (violation.propertyPath === 'plainPassword') {
                  this.form.get('password').setErrors({apiError: true});
                  this.asyncValidatorMsg.plainPassword = violation.message;
                } else {
                  this.asyncValidatorMsgDesconocido = error['hydra:description'];
                  this.alertErrorIsShow = true;
                }
              });
            } else {
              this.asyncValidatorMsgDesconocido = error['hydra:description'];
              this.alertErrorIsShow = true;
            }
          }
        }
      );
    }
  }

  passwordVisibility(): void {
    this.visiblePassword = !this.visiblePassword;

    if (this.visiblePassword) {
      this.inputPasswordType = 'text';
    } else {
      this.inputPasswordType = 'password';
    }
  }

}
