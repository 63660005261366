import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProgressBarModule } from '../components/progress-bar/progress-bar.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { MatRippleModule } from '@angular/material/core';
import { ScrollbarModule } from '../components/scrollbar/scrollbar.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { MatMenuModule } from '@angular/material/menu';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { PopoverModule } from '../components/popover/popover.module';
import { ToolbarUserComponent } from './toolbar/toolbar-user/toolbar-user.component';
import { ToolbarUserDropdownComponent } from './toolbar/toolbar-user/toolbar-user-dropdown/toolbar-user-dropdown.component';
import { ChangePasswordDialogComponent } from './toolbar/change-password-dialog/change-password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { AlertModule } from '../components/alert/alert.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarNotificationsDropdownComponent } from './toolbar/toolbar-notifications/toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { RelativeDateTimeModule } from '../pipes/relative-date-time/relative-date-time.module';
import { MatBadgeModule } from '@angular/material/badge';
import { ClockoutDialogModule } from '../components/clockout-dialog/clockout-dialog.module';



@NgModule({
  declarations: [
    LayoutComponent,
    SidenavComponent,
    SidenavItemComponent,
    ToolbarComponent,
    ToolbarUserComponent,
    ToolbarUserDropdownComponent,
    ChangePasswordDialogComponent,
    ToolbarNotificationsComponent,
    ToolbarNotificationsDropdownComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    FlexLayoutModule,
    IconModule,
    ProgressBarModule,
    ScrollbarModule,
    PopoverModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDividerModule,
    AlertModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RelativeDateTimeModule,
    MatBadgeModule,
    ClockoutDialogModule
  ],
  exports: [LayoutComponent]
})
export class LayoutModule { }
