import { ApplicationRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { SwUpdate } from '@angular/service-worker';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icStore from '@iconify/icons-ic/twotone-store';
import icCustomer from '@iconify/icons-ic/twotone-person-pin';
import icUsers from '@iconify/icons-ic/twotone-supervisor-account';
import icAnalytics from '@iconify/icons-ic/twotone-insert-chart';
import { NavigationService } from './shared/services/navigation.service';
import { SplashScreenService } from './shared/services/splash-screen.service';
//  import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AuthService } from './shared/services/auth.service';
import { AuthUserJsonld } from './shared/api-schemas/auth-user-jsonld.schema';
//  import { isEmpty } from 'lodash';
import { LanguageService } from './shared/services/language.service';
import { Router, RouterEvent, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { SwUpdateService } from './shared/services/sw-update.service';
import { environment } from 'src/environments/environment';
import { ClockingService } from './shared/services/clocking.service';
import { MatDialog } from '@angular/material/dialog';
import { ClockingDialogComponent } from './shared/components/clocking-dialog/clocking-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  @ViewChild('dashboardI18n', { static: true }) private dashboardI18n: ElementRef;
  @ViewChild('storesI18n', { static: true }) private storesI18n: ElementRef;
  @ViewChild('listEnabledI18n', { static: true }) private listEnabledI18n: ElementRef;
  @ViewChild('listDisabledI18n', { static: true }) private listDisabledI18n: ElementRef;
  @ViewChild('customersI18n', { static: true }) private customersI18n: ElementRef;
  @ViewChild('usersI18n', { static: true }) private usersI18n: ElementRef;
  @ViewChild('AI18n', { static: true }) private AI18n: ElementRef;
  @ViewChild('bI18n', { static: true }) private bI18n: ElementRef;

  appUpdate = false;

  constructor(/*appRef: ApplicationRef,
              private swUpdate: SwUpdate,*/
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private authService: AuthService,
              private languageService: LanguageService,
              private router: Router,
              private swUpdateService: SwUpdateService,
              private clockingService: ClockingService,
              private dialog: MatDialog
  ) {
    this.languageService.lang$.subscribe(lang => {
      Settings.defaultLocale = lang;
      if (lang !== this.localeId) {
        if (environment.production) {
          window.location.href = `https://app.procellpos.com/${lang}`;
        }
      }
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.swUpdateService.appUpdate$.subscribe(update => this.appUpdate = update);

    this.router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationStart)
    ).subscribe((e: NavigationStart) => {
      if (this.appUpdate) {
        document.location.reload();
      }
    });

    /* if (this.swUpdate.isEnabled) {
      console.log('swUpdate is available');

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    // const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHours$ = interval(6000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());

      this.swUpdate.unrecoverable.subscribe(event => console.log(event));
      this.swUpdate.available.subscribe(event => {
      console.log(event);
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
      this.swUpdate.activated.subscribe(event => {
      console.log(event);
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    // this.swUpdate.available.subscribe(event => {
    //   if (promptUser(event)) {
    //     this.swUpdate.activateUpdate().then(() => document.location.reload());
    //   }
    // });



      // this.swUpdate.available.subscribe(event => {
      //   console.log('current version is', event.current);
      //   console.log('available version is', event.available);
      // });
      // this.swUpdate.activated.subscribe(event => {
      //   console.log('old version was', event.previous);
      //   console.log('new version is', event.current);
      // });

      this.swUpdate.available.subscribe(() => {

          if (confirm('New version available. Load New Version?')) {
            document.location.reload();
          }
      });
    } */
  }

  ngOnInit(): void {
    const authUser = this.authService.jwtToken?.user;

    this.clockingService.isDayStartedEvent.subscribe(isDayStarted => {
      if (!isDayStarted) {
        this.openModalClokinDay();
      }
    });

    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    if (authUser) {
      this.createSidenav(authUser);
    } else {
      console.log(authUser);
      this.authService.logout();
    }

    this.authService.isAuthenticated$.subscribe(isAuth => {
      if (isAuth) {
        this.createSidenav(this.authService.jwtToken.user);
      }
    });
  }

  private openModalClokinDay(): void {
    const dialogRef = this.dialog.open(ClockingDialogComponent, {
      disableClose: true,
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  private checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationEnd) {
      if (this.authService.isTech() && !this.clockingService.isDayStarted) {
        this.clockingService.isDayStartedEvent.emit(false);
      }
    }
  }

  private createSidenav(user: AuthUserJsonld): void {
    const authUser = this.authService.jwtToken?.user;
    const isTech = this.authService.isGranted(['ROLE_TECH'], authUser.roles);
    const isAdmin = this.authService.isGranted(['ROLE_ADMIN'], authUser.roles);
    const isAdminStore = this.authService.isGranted(['ROLE_ADMIN_STORE'], authUser.roles);
    const isUser = this.authService.isGranted(['ROLE_USER'], authUser.roles);

    this.navigationService.items = [
      {
        type: 'link',
        label: this.dashboardI18n.nativeElement.innerText,
        route: '/dashboard',
        icon: icLayers,
        visible: isAdmin,
      },
      {
        type: 'link',
        label: 'Repairs',
        route: '/repairs',
        icon: icLayers,
        visible: isAdmin || isTech,
      },
      {
        type: 'dropdown',
        label: 'Reports',
        icon: icAnalytics,
        children: [
          {
            type: 'link',
            label: 'Dashboard',
            route: '/reports',
            visible: true,
          },
          {
            type: 'link',
            label: 'Profit & Expenses',
            route: '/reports/profit-expenses',
            visible: true
          },
          {
            type: 'link',
            label: 'Activations',
            route: '/reports/activations',
            visible: true
          },
          {
            type: 'link',
            label: 'Sales Report',
            route: '/reports/amount-sales',
            visible: true
          },
          {
            type: 'link',
            label: 'Inventory Cost',
            route: '/reports/inventory-cost',
            visible: true
          },
          {
            type: 'link',
            label: 'Most Sold Items',
            route: '/reports/most-sale-items',
            visible: true
          },
          {
            type: 'link',
            label: 'Re-Stock Report',
            route: '/reports/re-stock-report',
            visible: true
          },
        ],
        visible: isAdmin || isAdminStore,
      },
      {
        type: 'dropdown',
        label: this.storesI18n.nativeElement.innerText,
        icon: icStore,
        children: [
          {
            type: 'link',
            label: this.listEnabledI18n.nativeElement.innerText,
            route: '/stores',
            visible: true,
          },
          {
            type: 'link',
            label: this.listDisabledI18n.nativeElement.innerText,
            route: '/stores/disable',
            visible: true
          },
        ],
        visible: isAdmin,
      },
      {
        type: 'link',
        label: this.customersI18n.nativeElement.innerText,
        icon: icCustomer,
        route: '/customers',
        visible: isAdmin,
      },
      {
        type: 'link',
        label: this.usersI18n.nativeElement.innerText,
        icon: icUsers,
        route: '/users',
        visible: (!user.isGrandson && user['@type'] === 'PosUser') || isAdmin,
      }
    ];
  }
}
