<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading" i18n>Notifications</div>
      <div class="dropdown-subheading" i18n>You have {{ qtyNotifications }} new notifications.</div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
    <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
  </div>

  <div class="dropdown-content">
    <a *ngIf="appUpdate"
       (click)="reload()"
       href="javascript:void(0);"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <ic-icon [icon]="icUpdate"
               class="notification-icon text-primary"
               fxFlex="none"
               size="24px"></ic-icon>
      <div fxFlex="auto">
        <div class="notification-label" i18n>New version available. Load New Version?</div>
        <div class="notification-description">{{ appUpdateTime | appRelativeDateTime }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <!-- <button color="primary" mat-button type="button">MARK ALL AS READ</button> -->
  </div>
</div>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu> -->
