<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center">
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <div class="px-1" *ngIf="isTech">
    <button (click)="clockout()" mat-raised-button type="button" color="warn">
      <mat-icon class="mr-2" style="color: white">timer-off</mat-icon>
      CLOCKOUT
    </button>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">



    <div class="px-1">
      <app-toolbar-notifications></app-toolbar-notifications>
    </div>

    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon *ngIf="lang === 'en'" [icIcon]="emojioneUS"></mat-icon>
        <mat-icon *ngIf="lang === 'es'" [icIcon]="emojioneCU"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <app-toolbar-user></app-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button (click)="toogleLang('en')" mat-menu-item>
        <mat-icon [icIcon]="emojioneUS"></mat-icon>
        <span i18n>English</span>
      </button>

      <button (click)="toogleLang('es')" mat-menu-item>
        <mat-icon [icIcon]="emojioneCU"></mat-icon>
        <span i18n>Spanish</span>
      </button>
    </mat-menu>
  </div>
</div>
