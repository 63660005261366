<div [class.is-mobile]="!(isDesktop$ | async)"
     [class.scroll-disabled]="scrollDisabled$ | async"
     [class.sidenav-collapsed]="sidenavCollapsed$ | async"
     [class.content-container]="containerEnabled$ | async"
     class="page-container horizontal-layout toolbar-fixed">

  <app-progress-bar></app-progress-bar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav
                 [disableClose]="isDesktop$ | async"
                 [fixedInViewport]="!(isDesktop$ | async)"
                 [mode]="!(isDesktop$ | async) ? 'over' : 'side'"
                 [opened]="(isDesktop$ | async)"
                 class="sidenav">
      <app-sidenav [collapsed]="sidenavCollapsed$ | async"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <app-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
                   [mobileQuery]="!(isDesktop$ | async)"
                   class="vex-toolbar"></app-toolbar>

      <main class="content">
        <router-outlet></router-outlet>
      </main>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

