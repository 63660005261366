<h2 mat-dialog-title>Clockout</h2>

<div mat-dialog-content class="mat-typography">
  <p>
    Confirm clockout!
  </p>
</div>

<div mat-dialog-actions style="width: 100%; display: flex; justify-content: end">
  <button mat-button mat-dialog-close>Cancel</button>

  <button (click)="clockout()" [disabled]="loading" type="button" mat-raised-button color="warn">
    <span *ngIf="!loading">Clockout</span>
    <span *ngIf="loading">Loading...</span>
  </button>
</div>

