<template #dashboardI18n i18n>Dashboard</template>
<template #storesI18n i18n>Stores</template>
<template #listEnabledI18n i18n>List Enabled</template>
<template #listDisabledI18n i18n>List Disabled</template>
<template #customersI18n i18n>Customers</template>
<template #usersI18n i18n>Users</template>
<template #AI18n i18n>Users</template>
<template #bI18n i18n>Users</template>
<template #cI18n i18n>Users</template>
<template #dI18n i18n>Users</template>

<router-outlet></router-outlet>
