import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Observable, of, throwError, EMPTY, Subject } from 'rxjs';
import { catchError, retryWhen, mergeMap, delay, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { ToastNotificationService } from '../components/toast-notification/toast-notification.service';
import { FORBIDDEN, INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from '../services/http-client.service';
import { BAD_REQUEST } from 'src/app/shared/services/http-client.service';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router,
              /* private toastNotificationService: ToastNotificationService */) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error, request, next)));
  }

  private retryFailedHttpRequests(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      delayedRetry(3000),
      catchError((error) => {
        if (error.status === 0) {
          return EMPTY;
        }

        return this.errorHandler(error, request, next);
      }),
      shareReplay()
    );
  }

  private errorHandler(response: HttpEvent<any>, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line: no-string-literal
    const httpErrorCode = response['status'];
    switch (httpErrorCode) {
      case BAD_REQUEST:
        break;
      case INTERNAL_SERVER_ERROR:
        // this.toastNotificationService.showError('Ha ocurrido un error inesperado, intente nuevamente.');
        break;
      case NOT_FOUND:
        // if (this.router.url !== '/') { this.router.navigateByUrl(''); }
        break;
      case UNAUTHORIZED:
        if (this.authService.isLogin) {
          this.authService.logout();
          if (this.router.url !== '/') { this.router.navigateByUrl(''); }
        }
        break;
      case FORBIDDEN:
        if (this.authService.isLogin) {
          this.authService.logout();
          if (this.router.url !== '/') { this.router.navigateByUrl(''); }
        }
        break;
      default:
        console.log('Interceptor Http Error code', httpErrorCode);
        if (request.url === `${environment.apiBaseUrl}api/authenticated_user`) {
          return this.retryFailedHttpRequests(request, next);
        } else {
          // this.toastNotificationService.showError('Se produjo un problema con tu conexión. Compruebe su conexión a internet e inténtalo de nuevo.');
        }

        break;
    }

    throw response;
  }
}

const getErrorMessage = (maxRetry: number) => `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;
function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) => src.pipe(
    retryWhen((errors: Observable<any>) =>
      errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
            return of(error).pipe(delay(backoffTime));
          }

          return throwError(getErrorMessage(maxRetry));
        })
      )
    )
  );
}
