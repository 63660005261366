import { Component, OnInit } from '@angular/core';
import icUpdate from '@iconify/icons-ic/twotone-system-update';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import { SwUpdateService } from 'src/app/shared/services/sw-update.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  icUpdate = icUpdate;
  icSettings = icSettings;
  icChevronRight = icChevronRight;

  appUpdate: boolean;
  appUpdateTime: DateTime;
  qtyNotifications = 0;

  constructor(private swUpdateService: SwUpdateService) {
    this.swUpdateService.appUpdate$.subscribe(update => {
      this.appUpdate = update;
      this.appUpdateTime = DateTime.local();
      if (update) {
        this.qtyNotifications++;
      }
      console.log(update, this.qtyNotifications);
    });
  }

  ngOnInit(): void {
  }

  reload(): void {
    document.location.reload();
  }

}
