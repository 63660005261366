import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthCredentials } from '../api-schemas/auth-credentials.schema';
import { AuthToken } from '../api-schemas/auth-token.schema';
import { AuthUserJsonld } from '../api-schemas/auth-user-jsonld.schema';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  static jwtAuthToken = 'auth_token';

  private isAuthenticatedSubject = new ReplaySubject<boolean>(0);

  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();


  get jwtToken(): AuthToken | null {
    try {
      return JSON.parse(localStorage.getItem(AuthService.jwtAuthToken)) || null;
    } catch {
      return null;
    }
  }

  set jwtToken(token: AuthToken) {
    localStorage.setItem(AuthService.jwtAuthToken, JSON.stringify(token));
  }

  get authUser(): AuthUserJsonld | null {
    if (this.jwtToken?.user) {
      return this.jwtToken.user;
    } else {
      return null;
    }
  }

  get isLogin(): boolean {
    return this.jwtToken?.token ? true : false;
  }

  constructor(private httpClient: HttpClient) { }

  ngOnDestroy(): void {
    this.isAuthenticatedSubject.complete();
  }

  login(credentials: AuthCredentials, path: string): Observable<AuthToken> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}${path}`, JSON.stringify(credentials), {
        headers: new HttpHeaders({
          Accept: 'application/ld+json',
          'Content-Type': 'application/json'
        }),
        observe: 'body'
      })
      .pipe(
        map((response: AuthToken) => {
          this.jwtToken = response;
          this.isAuthenticatedSubject.next(true);
          return response;
        })
      );
  }

  logout(): void {
    try {
      window.localStorage.removeItem(AuthService.jwtAuthToken);
    } catch { }

    this.isAuthenticatedSubject.next(false);
  }

  isGranted(roles: string[], authRoles: string[]): boolean {
    let isGranted = false;

    authRoles.forEach(authRole => {
      roles.forEach(role => {
        if (authRole === role) {
          isGranted = true;
        }
      });
    });

    return isGranted;
  }

  isAdmin(): boolean {
    const authUser = this.authUser;

    if (authUser) {
      return this.isGranted(['ROLE_ADMIN'], authUser.roles);
    } else {
      return false;
    }
  }

  isAdminStore(): boolean {
    const authUser = this.authUser;

    if (authUser) {
      return this.isGranted(['ROLE_ADMIN', 'ROLE_ADMIN_STORE'], authUser.roles);
    } else {
      return false;
    }
  }

  isTech(): boolean {
    const authUser = this.authUser;

    if (authUser) {
      return this.isGranted(['ROLE_TECH'], authUser.roles);
    } else {
      return false;
    }
  }

}
