import { FormGroup } from '@angular/forms';

export const equalToValidator = (
  group: FormGroup,
  fieldName1: string,
  fieldName2: string,
  errorCode: string
): { [errorCode: string]: true } | null => {
  const field1 = group.controls[fieldName1].value;
  const field2 = group.controls[fieldName2].value;

  return field1 === field2 ? null : { [errorCode]: true };
};
