import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { alertAnimation } from '../../animations/alert.animation';
import icClose from '@iconify/icons-ic/twotone-close';
import { ThemePalette } from '@angular/material/core';
import { Observable, Subject, timer } from 'rxjs';
import { delay, share, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  template: `
    <div *ngIf="isShow"
         @alert
         [style]="styles"
         fxLayout="row"
         fxLayoutAlign="start center"
         class="alert-item"
         [ngClass]="{
           'text-primary-contrast bg-primary': color === 'primary',
           'text-accent-contrast bg-accent': color === 'accent',
           'text-warn-contrast bg-warn': color === 'warn'
         }">
      <div fxFlex="auto">
        <ng-content select="[content]"></ng-content>
      </div>
      <div *ngIf="closeButton" fxFlex="auto">
        <button (click)="close()"
                class="btn-close-alert"
                [ngClass]="{
                  'text-primary-contrast': color === 'primary',
                  'text-accent-contrast': color === 'accent',
                  'text-warn-contrast': color === 'warn'
                }"
                mat-icon-button
                type="button" >
          <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./alert.component.scss'],
  animations: [alertAnimation],
})
export class AlertComponent implements OnInit {
  @Input()
  set isShow(value: boolean) {
    this._isShow = value;

    if (value && this.duration !== false) {
      this.intervalSubject.next(void 0);
    }
  }
  get isShow(): boolean {
    return this._isShow;
  }

  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';

  @Input() styles: string|{[key: string]: string | undefined | null}|Array<string>;

  @Input() closeButton = true;

  @Input() duration: number|false = 5000;

  @Output() dimissEvent = new EventEmitter();

  icClose = icClose;

  private _isShow: boolean;
  private intervalSubject = new Subject();

  ngOnInit(): void {
    if (this.duration !== false) {
      this.intervalSubject.asObservable().pipe(
        startWith(0),
        switchMap(() => timer(0, Number(this.duration)))
      ).subscribe(i => {
        if (i === 1) {
          this._isShow = false;
          this.dimissEvent.emit();
        }
      });
    }
  }

  close(): void {
    this._isShow = false;
    this.dimissEvent.emit();
  }

}
