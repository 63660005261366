import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpRequestTotalService } from '../services/http-request-total.service';

@Injectable()
export class HttpRequestCountInterceptor implements HttpInterceptor {
  pendingRequestCount = 0;

  constructor(private httpRequestTotalService: HttpRequestTotalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pendingRequestCount++;
    this.httpRequestTotalService.total = this.pendingRequestCount;

    return next.handle(request).pipe(
      finalize(() => {
        this.pendingRequestCount--;
        this.httpRequestTotalService.total = this.pendingRequestCount;
      })
    );
  }
}
