import { Component } from '@angular/core';
// import { MenuItem } from '../interfaces/menu-item.interface';
// import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icLock from '@iconify/icons-ic/twotone-lock';
import icTimeOff from '@iconify/icons-ic/twotone-timer-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from 'src/app/shared/components/popover/popover-ref';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../../change-password-dialog/change-password-dialog.component';
import { ClockoutDialogComponent } from '../../../../components/clockout-dialog/clockout-dialog.component';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'app-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss']
})
export class ToolbarUserDropdownComponent {

  icLock = icLock;
  icTimeOff = icTimeOff;

  /* items: MenuItem[] = [
    {
      id: '1',
      icon: icLock,
      label: 'Change Password',
      description: 'Change your password at any time',
      colorClass: 'text-teal',
      route: '/apps/social'
    },
  ];
 */
  // trackById = trackById;
  icPerson = icPerson;
  icChevronRight = icChevronRight;

  authTech = false;
  username: string;

  constructor(private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              protected authService: AuthService,
              private router: Router,
              private dialog: MatDialog
  ) {
    this.username = this.authService?.authUser?.fname;
    this.authTech = this.authService.isTech();
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.popoverRef.close();
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  openChangePasswordDialog(): void {
    this.dialog.open(ChangePasswordDialogComponent);
  }

  clockout(): void {
    this.popoverRef.close();
    const dialogRef =  this.dialog.open(ClockoutDialogComponent, {
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
