import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneCU from '@iconify/icons-emojione/flag-for-flag-cuba';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icSearch from '@iconify/icons-ic/twotone-search';
import { LanguageService } from '../../services/language.service';
import { ClockoutDialogComponent } from '../../components/clockout-dialog/clockout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  icSearch = icSearch;
  emojioneUS = emojioneUS;
  emojioneCU = emojioneCU;
  icMenu = icMenu;

  lang: 'en'|'es';

  isTech = false;

  constructor(private layoutService: LayoutService,
              private languageService: LanguageService,
              private authService: AuthService,
              private dialog: MatDialog) {
    this.languageService.lang$.subscribe(lang => this.lang = lang);
    this.isTech = this.authService.isTech();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  toogleLang(lang: 'en'|'es'): void {
    this.languageService.lang = lang;
  }

  clockout(): void {
    const dialogRef =  this.dialog.open(ClockoutDialogComponent, {
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
